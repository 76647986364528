<template>
  <v-app-bar color="primary" app>
    <v-app-bar-nav-icon @click="drawer = !drawer" class="mr-2" />

    <v-spacer />

    <span>{{ user.name }}</span>
    <v-divider class="mx-4" inset vertical />
    <v-icon @click="$router.push(`/perfil/${user.user_id}`)">person</v-icon>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("user", ["user"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit("SET_DRAWER", value);
      }
    }
  }
};
</script>
