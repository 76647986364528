<template>
  <v-app v-if="user">
    <SideBar />
    <TopBar />
    <!-- Content -->
    <v-main>
      <slot />
    </v-main>
    <!-- / Content -->
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import SideBar from "@/components/ui/SideBar";
import TopBar from "@/components/ui/TopBar";

export default {
  name: "LayoutDefault",
  components: {
    SideBar,
    TopBar
  },
  computed: {
    ...mapState("user", ["user"])
  }
};
</script>
